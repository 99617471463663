<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div

      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >

      <!-- <span
        v-if="userData && userData.role && userData.role.expiredIn && appName === 'Resident'"
        class="font-small-3"
      >Số phòng: <strong>{{ userData.role.maxSlots && userData.role.maxSlots > 0 ? Number(userData.role.maxSlots).toLocaleString() : 'Không giới hạn' }}</strong> | Ngày hết hạn: <strong>{{ parseDateToString(userData.role.expiredIn) }}</strong> | Số tin ZNS: <strong>{{ Number(Math.max(userData.role.zaloZnsCount, 0)).toLocaleString() }}</strong></span> -->

      <span
        v-if="userData && userData.role && userData.role.expiredIn && appName !== 'TingTong'"
        class="text-danger font-weight-bolder"
      >
        <bookmarks />
        <!-- <feather-icon
          class=" font-medium-3 mr-50"
          icon="GiftIcon"
        /> <span class="animate-character">MỚI: Gạch nợ tự động, Công tơ điện tử, Tự động thông báo</span> -->
      </span>
      <span
        v-if="appName !== 'Resident' && appName !== 'VMarket'"
        class="font-weight-bolder text-white"
      >PHẦN MỀM QUẢN LÝ VẬN HÀNH TÒA NHÀ {{ appName.toUpperCase() }}</span>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->

      <!-- <ProcessDropDown /> -->
      <dark-Toggler class="d-none d-lg-block" />

      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import {
  getUserData, parseDateToString, updateUserAbilities, updateUserConfiguration,
} from '@/auth/utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import useJwt from '@/auth/jwt/useJwt';
import { $themeConfig } from '@themeConfig';
import NotificationDropdown from './NotificationDropdown.vue';
// import Locale from './Locale.vue';
import UserDropdown from './UserDropdown.vue';
import Bookmarks from './Bookmarks.vue';
// import ProcessDropDown from './ProcessDropDown.vue';

export default {
  components: {
    BLink,
    BNavbarNav,
    Bookmarks,
    // Navbar Components
    DarkToggler,
    NotificationDropdown,
    // Locale,
    UserDropdown,
    // ProcessDropDown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  // sockets: {
  //   importProcessUpdate(data) {
  //     // if (data && data.items && data.items.length > 0) {
  //     //   const filterItems = data.items.filter(object => object.success === false);
  //     //   if (filterItems && filterItems.length > 0) {
  //     //     const item = filterItems[filterItems.length - 1];
  //     //     this.$toast({
  //     //       component: ToastificationContentVue,
  //     //       props: {
  //     //         title: item.title,
  //     //         text: item.description,
  //     //         icon: 'UploadIcon',
  //     //         variant: 'danger',
  //     //       },
  //     //     });
  //     //   }
  //     // }
  //     if (data && data.status === 'success') {
  //       this.$toast({
  //         component: ToastificationContentVue,
  //         props: {
  //           title: 'Import thành công',
  //           text: 'Hãy kiểm tra dữ liệu để chắc chắn rằng tất cả thông tin đã được import nhé',
  //           icon: 'UploadIcon',
  //           variant: 'success',
  //         },
  //       });
  //     }
  //     // } else if (data && data.status === 'fail') {
  //     //   this.$toast({
  //     //     component: ToastificationContentVue,
  //     //     props: {
  //     //       title: 'Import thất bại',
  //     //       text: 'Hãy kiểm tra lại dữ liệu nhé',
  //     //       icon: 'UploadIcon',
  //     //       variant: 'danger',
  //     //     },
  //     //   });
  //     // }
  //   },
  // },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      parseDateToString,
    };
  },
  mounted() {
    // this.$socket.connect();
    // this.$socket.emit('join', {});
    this.refetchPermissions();
    this.refetchUserConfiguration();
  },
  methods: {
    refetchPermissions() {
      const userData = getUserData();
      if (userData) {
        useJwt.getPermissions().then(permissionResponse => {
          updateUserAbilities(permissionResponse.data);
          this.$ability.update(permissionResponse.data);
        }).catch(() => {

        });
      }
    },
    refetchUserConfiguration() {
      const userData = getUserData();
      if (userData) {
        useJwt.getUserConfiguration().then(userConfigurationResponse => {
          updateUserConfiguration(userConfigurationResponse.data);
        });
      }
    },
  },
  setup() {
    const { appName } = $themeConfig.app;
    const userData = getUserData();

    return {
      appName,
      userData,
    };
  },
};
</script>

<style lang="scss">
.animate-character
{
  //  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>
